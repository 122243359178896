@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #c5e3ec;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Comic Sans MS', sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bpHeaderContentTitle{
  text-align: center !important;
}

.bpHeaderContainer{
  display: none !important;
  
}
.bpContainer{
  border-radius: 0% !important;
  background: #c5e3ec !important;
}

.bpMessageListScrollbarContainer{
  border-radius: 10px !important;
  flex: 1;
  background: white !important;
}

.bpComposerContainer {
  box-shadow: 4px 4px 10px black;
  
}

.\#l3nlsz{
  background-color: #00a7cc !important ;
  color: white !important;
}

.\#1bk5mm5[data-direction=outgoing] .\#g0k8t5{
  background-color: #00a7cc !important;
}

.css-b62m3t-container{
  height: 100% !important;
  border:none !important;
  outline: none !important;
}

#bpContainer {
  position: fixed;
  bottom: 0;
  left: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.guide-modal {
  position: absolute;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.guide-modal p {
  margin-bottom: 10px;
  font-size: 16px;
}

.btn {
  padding: 10px 20px;
  background-color: #00a7cc;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.btn:hover {
  background-color: #00a7cc;
}
